define("discourse/plugins/discourse-user-card-badges/discourse/initializers/initialize-user-card-badges", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function modifyUserCardContents(api) {
    var _dec, _obj;
    api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user.card_badge.image"), (_obj = {
      pluginId: "discourse-user-card-badges",
      classNameBindings: ["hasCardBadgeImage"],
      hasCardBadgeImage(image) {
        return image && image.indexOf("fa-") !== 0;
      }
    }, (_applyDecoratedDescriptor(_obj, "hasCardBadgeImage", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasCardBadgeImage"), _obj)), _obj)));
  }
  var _default = _exports.default = {
    name: "user-card-badges",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.19", api => {
        modifyUserCardContents(api);
      });
    }
  };
});